<mat-toolbar class="header">
  <!-- <span class="example-spacer"></span> -->
  <img
    class="logo"
    src="../../assets/images/MySembraTeal.svg"
    alt="SembraCare Logo"
  />
  <span class="example-spacer"></span>

  <div style="display: flex;justify-content: space-between;align-items: center;">
    <div *ngIf="agencyCodeList.length > 0 && isShowAgency">
      <mat-form-field class="example-full-width">
        <mat-select
          id="ac"
          #ac="ngModel"
          [(ngModel)]="selectedAgencyCode"
          name="acs"
          placeholder="AgencyCode"
          (selectionChange)="onAgencyCodeChange($event.value)"
        >
          <mat-option *ngFor="let ac of agencyCodeList" [value]="ac">
            {{ ac }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>


    <div *ngIf="isUserAuthenticated(); else publicNav">
      <button mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon>menu</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="goTo('home')">
          <span>
            {{ "HOME.TITLE" | translate }}
            <mat-spinner *ngIf="showLoader" [diameter]="20"></mat-spinner>
          </span>
        </button>
        <button mat-menu-item (click)="goTo('profile')">
          <span>{{ "HOME.PROFILE" | translate }}</span>
        </button>
        <!-- <button mat-menu-item (click)="gotoThreadsPriority()">
          <span>{{ "HOME.THREADS_PRIORITY" | translate }}</span>
        </button>
        <button mat-menu-item (click)="gotoThreadsCategory()">
          <span>{{ "HOME.THREADS_CATEGORY" | translate }}</span>
        </button>
        <button mat-menu-item (click)="gotoThreadsStatus()">
          <span>{{ "HOME.THREADS_STATUS" | translate }}</span>
        </button> -->

        <button mat-menu-item (click)="logOut()">
          <span>{{ "HOME.LOGOUT" | translate }}</span>
        </button>
      </mat-menu>
    </div>

    <ng-template #publicNav>
      <button mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon>menu</mat-icon>
      </button>
      <mat-menu #menu="matMenu" #publicNav>
        <button mat-menu-item (click)="goTo('login')">
          <span>{{ "LOGIN.TITLE" | translate }}</span>
        </button>

        <button mat-menu-item (click)="goTo('resend-code')">
          <span>{{ "LOGIN.VERIFY_ACCOUNT" | translate }}</span>
        </button>

        <!-- <button mat-menu-item (click)="goTo('signup')">
          <span>{{ "SIGNUP.TITLE" | translate }}</span>
        </button> -->
      </mat-menu>
    </ng-template>
  </div>




</mat-toolbar>

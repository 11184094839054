// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  appVersion: require('../../package.json').version + '-dev',
  production: false,
  staging: false,
  S3_FILE_URL: 'https://mysembrachat.s3.amazonaws.com/',
  // apiURL: 'https://chatapi.sembratest.com/', // 'http://localhost:3002/',
  AUTH_API_URL: 'https://usermgmt.mysembra.com/', 
  SEMBRATIME_URL: 'https://time.devsembra.com',
  TEMPLATE_URL: 'https://template.mysembra.com',
  SEMBRANET_URL: 'https://net.sembratest.com/auth/login',
  // SEMBRATIME_URL: 'http://localhost:3001/',
  firebase: {
    apiKey: 'AIzaSyCtyNmJobwYHXGKX8bodAOweNonWUz8xKc',
    authDomain: 'angularpush-6933c.firebaseapp.com',
    projectId: 'angularpush-6933c',
    storageBucket: 'angularpush-6933c.appspot.com',
    messagingSenderId: '697096402019',
    appId: '1:697096402019:web:6af286e8e52486cd97b456',
    measurementId: 'G-8JWR567H9P',
    vapidKey: 'BP1gzMZLV-Lb2lF9Ke52aM4YMIA7VoXz0MmmK7RbJ_ukj4wv3XJmuyOhM2yOPOcS0ex6-emfb25gBJwfx07Cykw'
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IdleTimeoutService {
  private timeoutSeconds: any;
  private warningSeconds: any;
  private timeoutHandle: any;
  private warningHandle: any;

  private onWarning$ = new Subject<void>();
  private onTimeout$ = new Subject<void>();

  constructor() {}

  startWatching(timeoutSeconds: number, warningSeconds: number): void {
    this.timeoutSeconds = timeoutSeconds;
    this.warningSeconds = warningSeconds;

    this.resetTimer();
  }

  resetTimer(): void {
    this.clearTimers();

    this.warningHandle = setTimeout(() => {
      this.onWarning$.next();
    }, this.warningSeconds * 1000);

    this.timeoutHandle = setTimeout(() => {
      this.onTimeout$.next();
    }, this.timeoutSeconds * 1000);
  }


  clearTimers(): void {
    if (this.warningHandle) clearTimeout(this.warningHandle);
    if (this.timeoutHandle) clearTimeout(this.timeoutHandle);
  }

  stopWatching(): void {
    this.clearTimers();
  }

  getWarningObservable(): Observable<void> {
    return this.onWarning$.asObservable();
  }

  getTimeoutObservable(): Observable<void> {
    return this.onTimeout$.asObservable();
  }
}

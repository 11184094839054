import { Component, OnInit } from '@angular/core';
import { environment } from '../environments/environment';
import {
  getMessaging,
  getToken,
  isSupported,
  onMessage,
} from 'firebase/messaging';
import { TranslateService } from '@ngx-translate/core';
import { BnNgIdleService } from 'bn-ng-idle';
import { ActivatedRoute, Router } from '@angular/router';
import { Platform } from '@angular/cdk/platform';
import { SwPush, SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { filter, map } from 'rxjs/operators';
import { PushNotificationsService } from './utilities/services/push-notification.service';
import { HttpService } from './utilities/services/http.service';
import { MatDialog } from '@angular/material/dialog';
import { IdleTimeoutService } from './utilities/services/idle-timeout.service';
import { ConfirmationDialogComponent } from './idle-timeout-dialog/idle-timeout-dialog.component';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'sembracare-chat-frontend';

  message: any = null;
  isOnline: boolean;
  modalVersion: boolean;
  modalPwaEvent: any;
  modalPwaPlatform: string | undefined;
  currentApplicationVersion = environment.appVersion;

  constructor(
    public translate: TranslateService,
    private router: Router,
    private bnIdle: BnNgIdleService,
    private platform: Platform,
    private swUpdate: SwUpdate,
    private swPush: SwPush,
    private httpService: HttpService,
    private activatedRoute: ActivatedRoute,
    private _notificationService: PushNotificationsService,
    private idleTimeoutService: IdleTimeoutService,
    private dialog: MatDialog
  ) {
    this.isOnline = false;
    this.modalVersion = false;

    translate.addLangs(['en', 'fr']);
    translate.setDefaultLang('en');
    const browserLang = translate.getBrowserLang() || 'en';
    translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');
  }

  isStaging() {
    return environment.staging;
  }

  ngOnInit(): void {
    // this.idleTimeoutService.startWatching(300, 240);
    this.idleTimeoutService.startWatching(1500, 1440);

    this.idleTimeoutService.getWarningObservable().subscribe(() => {
      if (this.router.url !== '/login') {
        console.log("inside");
        this.showWarningDialog();
      }
    });

    this.idleTimeoutService.getTimeoutObservable().subscribe(() => {
      this.logout();
    });

    this.setupUserActivityListener();
    this.activatedRoute.queryParams.subscribe((params) => {
      // console.log(params);
      if (params['token']) {
        let refreshToken = params['token'];
        let Username = params['email'];

        console.log(Username);
        console.log(refreshToken);
        if (Username && refreshToken) {
          const data = {
            realm: 'Sembracare',
            Username: Username,
            Refreshtoken: refreshToken,
          };
          this.magicLogin(data);
        }
      } else {
        const token = localStorage.getItem('userRealm');
        if (token && this.router.url === '/') {
          // Redirect to /home only if on root path
          this.router.navigate(['/home']);
        }
      }
    });



    this._notificationService.requestPermission();
    this.updateOnlineStatus();

    window.addEventListener('online', this.updateOnlineStatus.bind(this));
    window.addEventListener('offline', this.updateOnlineStatus.bind(this));

    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates.pipe(
        filter(
          (evt: any): evt is VersionReadyEvent => evt.type === 'VERSION_READY'
        ),
        map((evt: any) => {
          console.info(
            `currentVersion=[${evt.currentVersion} | latestVersion=[${evt.latestVersion}]`
          );
          this.modalVersion = true;
        })
      );
    }

    this.loadModalPwa();

    this.requestPermission();
    this.listen();

    // 300 = 5 minutes inactive time will logout from app
    // this.bnIdle.startWatching(300).subscribe((res) => {
    // 1500 = 25 minutes inactive time will logout from app
    this.bnIdle.startWatching(1500).subscribe((res) => {
      if (res) {
        const token = localStorage.getItem('userAccessToken');
        if (token) {
          localStorage.clear();
          this.router.navigate(['/login']);
        }
      }
    });
  }

  showWarningDialog() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: { warningTime: 60 },
    });

    this.idleTimeoutService.getTimeoutObservable().subscribe(() => {
      dialogRef.close();
      this.logout();
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.idleTimeoutService.resetTimer();
      } else {
        this.logout();
      }
    });
  }

  logout() {
    const currentUrl = this.router.url;
    localStorage.clear();
    localStorage.setItem('redirectUrl', currentUrl);
    this.router.navigate(['/login']);
  }

  setupUserActivityListener() {
    const resetEvents = ['mousemove', 'keydown', 'click', 'scroll'];
    resetEvents.forEach((event) => {
      window.addEventListener(event, () => {
        this.idleTimeoutService.resetTimer();
      });
    });
  }


  pushSubscription() {
    // if (!this.swPush.isEnabled) {
    //   console.log('Notifications is not enabled.');
    //   return;
    // }
    this.swPush
      .requestSubscription({ serverPublicKey: environment.firebase.vapidKey })
      .then((subscription) => {
        // send subscription to the server
        console.log('push', JSON.stringify(subscription));
        // localStorage.setItem('pushToken', subscription.getKey('token'));
      })
      .catch((error) => console.log(error));
  }

  async requestPermission() {
    const messaging = (await isSupported()) ? getMessaging() : null;
    if (messaging) {
      getToken(messaging, { vapidKey: environment.firebase.vapidKey })
        .then((currentToken) => {
          if (currentToken) {
            // console.log('Hurraaa!!! we got the token.....');
            // console.log(currentToken);
            localStorage.setItem('pushToken', currentToken);
            // Send the token to your server and update the UI if necessary
          } else {
            // Show permission request UI
            console.log(
              'No registration token available. Request permission to generate one.'
            );
            // ...
          }
        })
        .catch((err) => {
          console.log('An error occurred while retrieving token. ', err);
          // ...
        });
    }
  }
  async listen() {
    const messaging = (await isSupported()) ? getMessaging() : null;
    if (messaging) {
      onMessage(messaging, (payload) => {
        console.log('Message received. ', payload);
        this.message = payload;

        let data: Array<any> = [];
        data.push({
          title: payload.notification?.title,
          alertContent: payload.notification?.body,
          data: {
            url: payload?.data?.['url'],
          },
        });

        this._notificationService.generateNotification(data);
      });
    }
  }

  private updateOnlineStatus(): void {
    this.isOnline = window.navigator.onLine;
    console.info(`isOnline=[${this.isOnline}]`);
  }

  public updateVersion(): void {
    this.modalVersion = false;
    window.location.reload();
  }

  public closeVersion(): void {
    this.modalVersion = false;
  }

  private loadModalPwa(): void {
    if (this.platform.ANDROID) {
      window.addEventListener('beforeinstallprompt', (event: any) => {
        event.preventDefault();
        this.modalPwaEvent = event;
        this.modalPwaPlatform = 'ANDROID';
      });
    }

    if (this.platform.IOS && this.platform.SAFARI) {
      const isInStandaloneMode =
        'standalone' in window.navigator &&
        (<any>window.navigator)['standalone'];
      if (!isInStandaloneMode) {
        this.modalPwaPlatform = 'IOS';
      }
    }
  }

  public addToHomeScreen(): void {
    this.modalPwaEvent.prompt();
    this.modalPwaPlatform = undefined;
  }

  public closePwa(): void {
    this.modalPwaPlatform = undefined;
  }

  magicLogin(data: any) {
    this.httpService.magicLogin(data).subscribe({
      complete: () => {
        console.log('done');
      },
      error: (error: any) => {
        console.log(error);
      },
      next: (res: any) => {
        if (res) {
          if (res.accessToken) {
            localStorage.setItem('userEmail', data.Username.toLowerCase());
            localStorage.setItem('userId', data.Username.toLowerCase());
            localStorage.setItem('userAccessToken', res.idToken);
            localStorage.setItem('userRefreshToken', res.refreshToken);
            localStorage.setItem('userRealm', res.Realm);
            localStorage.setItem('Role', res.Role);
            localStorage.setItem('UserRole', res.UserRole);
            localStorage.setItem('agencycode', res.agencycode);
            localStorage.setItem('userName', res.userName);
            localStorage.setItem('allAgencyCode', res.agencycode);
            localStorage.setItem('agencycode', res.agencycode?.split(';')[0]);
            localStorage.setItem('_id', res._id);

            this.router.navigate(['/home']);
          }
        }
      },
    });
  }
}

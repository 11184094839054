import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpService } from '../utilities/services/http.service';
import { TranslateService } from '@ngx-translate/core';
import { AgencyRoleType } from '../utilities/enums/agencyrole.enum';
import { LocalStorageService } from '../utilities/services/local-storage.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  selectedAgencyCode: any = "";
  agencyCodeList: any = [];
  isShowAgency: boolean = false;
  showLoader: boolean = false;

  constructor(
    private router: Router,
    private _snackBar: MatSnackBar,
    private httpService: HttpService,
    public translate: TranslateService,
    private localStorageService: LocalStorageService
  ) { }

  ngOnInit(): void {
    this.initializeAgencyCode();
    this.isShowAgency = this.router.url !== '/login';
  }

  private initializeAgencyCode(): void {
    const storedString = localStorage.getItem('allAgencyCode');
    if (storedString) {
      this.agencyCodeList = storedString.split(';');
    }

    const savedCode = localStorage.getItem('agencycode');
    if (savedCode) {
      this.selectedAgencyCode = savedCode;
    } else if (this.agencyCodeList.length > 0) {
      this.selectedAgencyCode = this.agencyCodeList[0];
      localStorage.setItem('agencycode', this.selectedAgencyCode);
    }
  }

  onAgencyCodeChange(selectedCode: string): void {
    this.localStorageService.setItem('agencycode', selectedCode);
    this.router.navigateByUrl(this.router.url).then(() => {
    });
  }

  logOut() {
    const currentUrl = this.router.url;
    localStorage.clear();
    localStorage.setItem('redirectUrl', currentUrl);
    this.router.navigate(['/login']);
  }

  isUserAuthenticated() {
    const token = localStorage.getItem('userAccessToken');
    if (token) {
      return true;
    }
    return false;
  }

  ShowMenuCards() {
    const loginUserRealmRole = localStorage.getItem('Role');
    const loginUserRole = localStorage.getItem('UserRole');
    if (loginUserRole === AgencyRoleType.ADMIN) {
      // system user will access all functionality of the app
      return true;
    } else {
      if (
        loginUserRealmRole === AgencyRoleType.ADMIN ||
        loginUserRealmRole === AgencyRoleType.OWNER
      ) {
        // as per rule the above roles can chat with everyone
        return true;
      } else {
        return false;
      }
    }
  }

  goTo(link: string) {
    this.showLoader = true;
    this.router.navigate([`/${link}`]);
    this.showLoader = false;
  }

  gotoThreadsPriority() {
    this.router.navigate(['/threads-priority']);
  }

  gotoThreadsCategory() {
    this.router.navigate(['/threads-category']);
  }
  gotoThreadsStatus() {
    this.router.navigate(['/threads-status']);
  }

}

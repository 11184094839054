import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './utilities/authguard/auth.guard';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginModule),
  },
  // {
  //   path: 'signup',
  //   loadChildren: () =>
  //     import('./signup/signup.module').then((m) => m.SignUpModule),
  // },
  {
    path: 'onboard/:agency',
    loadChildren: () =>
      import('./onboarding-agency/onboarding-agency.module').then(
        (m) => m.OnboardingAgencyModule
      ),
  },
  {
    path: 'sembratime',
    loadChildren: () =>
      import('./onboarding-sembratime/onboarding-sembratime.module').then(
        (m) => m.OnboardingSembratimeModule
      ),
  },
  {
    path: 'verify-code',
    loadChildren: () =>
      import('./verify-code/verify-code.module').then(
        (m) => m.VerifyCodeModule
      ),
  },
  {
    path: 'resend-code',
    loadChildren: () =>
      import('./resend-code/resend-code.module').then(
        (m) => m.ResendCodeModule
      ),
  },
  {
    path: 'forgot-password',
    loadChildren: () =>
      import('./forgot-password/forgot-password.module').then(
        (m) => m.ForgotPasswordModule
      ),
  },
  {
    path: 'reset-password',
    loadChildren: () =>
      import('./reset-password/reset-password.module').then(
        (m) => m.ResetPasswordModule
      ),
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'unauthorize',
    loadChildren: () =>
      import('./unauthorize/unauthorize.module').then(
        (m) => m.UnauthorizeModule
      ),
  },
  {
    path: 'chat',
    loadChildren: () => import('./chat/chat.module').then((m) => m.ChatModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'broadcast',
    loadChildren: () =>
      import('./broadcast/broadcast.module').then((m) => m.BroadCastModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'user',
    loadChildren: () =>
      import('./search-user/search-user.module').then(
        (m) => m.SearchUserModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'delete-user',
    loadChildren: () =>
      import('./delete-user-realm/delete-user-realm.module').then(
        (m) => m.DeleteUserRealmModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./profile/profile.module').then((m) => m.ProfileModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'profile-pic',
    loadChildren: () =>
      import('./image-preview/image-preview.module').then(
        (m) => m.ImagePreviewModule
      ),
    canActivate: [AuthGuardService],
  },
  // {
  //   path: 'update-phone',
  //   loadChildren: () =>
  //     import('./update-phone/update-phone.module').then(
  //       (m) => m.UpdatePhoneModule
  //     ),
  //   canActivate: [AuthGuardService],
  // },
  // {
  //   path: 'update-email',
  //   loadChildren: () =>
  //     import('./update-email/update-email.module').then(
  //       (m) => m.UpdateEmailModule
  //     ),
  //   canActivate: [AuthGuardService],
  // },
  // {
  //   path: 'update-name',
  //   loadChildren: () =>
  //     import('./update-name/update-name.module').then(
  //       (m) => m.UpdateNameModule
  //     ),
  //   canActivate: [AuthGuardService],
  // },
  {
    path: 'pick-employee-shift',
    loadChildren: () =>
      import(
        './register-callout/pick-employee-shift/pick-employee-shift.module'
      ).then((m) => m.PickEmployeeShiftModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'pick-employee',
    loadChildren: () =>
      import(
        './register-callout/available-employee/available-employee.module'
      ).then((m) => m.AvailableEmployeeModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'register-callout',
    loadChildren: () =>
      import('./register-callout/register-callout.module').then(
        (m) => m.RegisterCallOutModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'fill-in-alert',
    loadChildren: () =>
      import('./register-callout/fill-in-alert/fill-in-alert.module').then(
        (m) => m.FillInAlertModule
      ),
  },
  {
    path: 'view-shifts',
    loadChildren: () =>
      import('./register-callout/view-shifts/view-shifts.module').then(
        (m) => m.ViewShiftModule
      ),
  },
  {
    path: 'view-shift-users',
    loadChildren: () =>
      import(
        './register-callout/view-shift-users/view-shift-users.module'
      ).then((m) => m.ViewShiftUsersModule),
  },
  {
    path: 'connections',
    loadChildren: () =>
      import('./connections/connections.module').then(
        (m) => m.ConnectionsModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'new-message',
    loadChildren: () =>
      import('./new-message/new-message.module').then(
        (m) => m.NewMessageModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'contact-info',
    loadChildren: () =>
      import('./contact-info/contact-info.module').then(
        (m) => m.ContactInfoModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'threads',
    loadChildren: () => import('./threads-home/threads-home.module').then((m) => m.ThreadsHomeModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'threads-priority',
    loadChildren: () => import('./threads-priority/threads-priority.module').then((m) => m.ThreadsPriorityModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'threads-category',
    loadChildren: () => import('./threads-category/threads-category.module').then((m) => m.ThreadsCategoryModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'threads-status',
    loadChildren: () => import('./threads-status/threads-status.module').then((m) => m.ThreadsStatusModule),
    canActivate: [AuthGuardService],
  },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: '**', redirectTo: '' },
  // {
  //   path: '',
  //   redirectTo: 'chat',
  //   pathMatch: 'full',
  // },
  // {
  //   path: 'chat',
  //   component: ChatComponent,
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

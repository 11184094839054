

<h2 class="dialog-header">
  <span class="title">Warning</span>

</h2>

<div class="dialog-content">
  <h1 mat-dialog-title>Session Timeout</h1>
  <div mat-dialog-content class="mt-2">
    <p>You will be logged out in {{ data.warningTime }} seconds due to inactivity. Do you want to stay logged in?</p>
  </div>
  <div mat-dialog-actions class="mt-2">


    <button type="button" class="btn btn-primary" (click)="onConfirm()">
      Stay Logged In
    </button>
    <button type="button" class="btn btn-secondary" style="margin-left: 10px;" (click)="onDecline()">
      Log Out
    </button>
  </div>

</div>
